@font-face {
  font-family: 'Peignot';
  src: url('../../asset/Peignot/Peignot.ttf');
  font-weight: 400;
  font-style: normal;
  }

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  line-height: 34px;
}

.cursor-pointer {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

:root {
  --black: #222222;
  --dark-green: #113A2D;
  --cream: #E9C37A;
  --white: #FFFFFF;
  --white-rgb: 255, 255, 255;
  --red: #DF584F;
  --grey: 89, 89, 89;
  --dark-grey: #444444;
  --light-grey: #BBBBBB;
  --white-grey: #F4F4F4;
  --light-blue: #E8F0FE;
  --font-family: 'Montserrat', sans-serif;
  --border-radius: 3px;
}