.iconRed {
    color: var(--red);
}

.icon {
    color: var(--cream);
}

.offer {
    color: var(--red);
}